<div id="search-component">
    <h4><label for="search-box">Hero Search</label></h4>
  
    <input #searchBox id="search-box" (input)="search(searchBox.value)" />
  
    <ul class="search-result">
      <li *ngFor="let hero of heroes$ | async" >
        <a routerLink="/detail/{{hero.id}}">
          {{hero.name}}
        </a>
      </li>
    </ul>
  </div>