<div *ngIf="hero">
    <h2>{{hero.name | uppercase}} Details</h2>
    <div><span>id: </span>{{hero.id}}</div>
    <div>
      <label>name:
        <input [(ngModel)]="hero.name" placeholder="name"/>
      </label>
    </div>
    <button (click)="goBack()">go back</button>
    <button (click)="save()">save</button>
  </div>